import { CountUp } from "countup.js";

const isVisible = el => {
  if (el.style.display === "none") return false;
  var rect = el.getBoundingClientRect();
  const offset = 100;
  return (
    rect.top >= 0 &&
    rect.bottom <= (window.innerHeight + offset || document.documentElement.clientHeight)
  );
};

(function() {
  const played = [];

  ["projects", "stars", "stickers", "markets"].forEach(countType => {
    const el = document.getElementById(`${countType}-count`);
    if (!el) return;

    const suffix = el.dataset.suffix;
    const target = el.dataset.target;

    window.addEventListener("scroll", () => {
      if (!isVisible(el) || played.indexOf(countType) !== -1) return;

      new CountUp(el, target, {
        suffix: suffix ? suffix : "",
        separator: ".",
        duration: 8
      }).start();
      played.push(countType);
    });
  });
})();